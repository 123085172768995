import { render, staticRenderFns } from "./10-warning-signs-of-a-debt-problem.md?vue&type=template&id=377a445c&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports